<template>
  <div>
    <div class="bg-gray-800 border-gray-700 timeline-content-row" :style="rowStyle" :class="{ 'bg-blue-200' : isDragTarget }" @dragenter="onDragEnter" @dragover="onDragOver" @dragleave="onDragLeave" @ondrop="onDrop" @drop="onDrop" />
  </div>
</template>
<script>
import iframeCommunication from '@mixins/iframe-communication-mixin.js'

export default {
  name: 'TimelineLayerEmpty',

  props: ['scale', 'overallduration'],
  mixins: [iframeCommunication],

  data() {
    return {
      isDragTarget: false
    }
  },

  computed: {

    fullwidthPx() {
      return this.overallduration * this.scale
    },

    rowStyle() {
      return 'width: ' + this.fullwidthPx + 'px'
    }

  },

  mounted() {
    this.registerIframeDragEmptyListeners()
  },

  methods: {

    onDragEnter(event) {
      this.isDragTarget = true
    },

    onDragLeave(event) {
      this.isDragTarget = false
    },

    onDragOver(event) {
      this.isDragTarget = true
    },

    onDrop(event) {
      this.isDragTarget = false

      let moduleId = event.dataTransfer.getData('application/x-previz.timeline-module')
      if (moduleId !== null && moduleId !== '') {
        this.handleModuleMove(moduleId, event)
      } else {
        let assetId = event.dataTransfer.getData('application/x-previz.asset')
        this.handleAssetDrop(assetId, event)
      }
    },

    handleAssetDrop(assetId, event) {
      let x = (event.offsetX) / this.scale
      this.$emit('new-module', { assetId: assetId, x: x, target:event.target })
    },

    handleModuleMove(moduleId, event) {
      // We need to figure out what the actual X movement has been
      // then we can scale it and use that to figure out the new start time
      let pointOffset = event.dataTransfer.getData('application/x-previz.timeline-module-touchoffset')
      let x = (event.offsetX - pointOffset) / this.scale

      this.$emit('drag-move-module', { moduleId: moduleId, x: x })
    }

  }
}
</script>
